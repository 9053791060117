<template>
	<div ref="itinerary" class="space-y-8" data-test="itinerary">
		<errorMessage v-if="$store.state.hasItineraryErrors" v-bind="$store.state.itineraryErrors" :promoCode="$store.state.itinerary.promoCode" :searchTypeAction="searchTypeAction" class="w-full tabletWide:w-8/12" />
		<form data-vv-scope="formItinerary" class="grid grid-cols-12 gap-8" @submit.prevent>
			<div class="col-span-12 tabletWide:col-span-4 tabletWide:col-start-1 tabletWide:row-start-1">
				<label :class="[errors.has('Pick-up location', 'formItinerary') ? 'border-goError bg-goErrorBright text-goError' : 'text-goBlack border-transparent focus-within:border-goGrayBright bg-goGrayBrighter']" for="locationPickerStart" class="w-full flex min-h-40 flex-col border-2 rounded-3 py-6 px-6" data-test="pickUpLocation">
					<span v-show="branchPicker.start" class="text-14 leading-20 text-goGrayDarker">Pick-up</span>
					<client-only>
						<multiselect v-if="$store.state.browser.breakpoint.isTablet" v-model="branchPicker.start" v-validate="'required'" :allow-empty="false" :searchable="false" :disabled="!$store.state.location.list.length" :options="$store.state.location.list" :show-labels="false" :option-height="24" class="h-24" placeholder="Pick-up location" name="Pick-up location" label="name" track-by="name" open-direction="bottom" />

						<select v-else id="locationPickerStart" v-model="branchPicker.start" v-validate="'required'" :disabled="!$store.state.location.list.length" name="Pick-up location" class="w-full border-0 bg-transparent p-0 outline-0 h-24 cursor-pointer">
							<option :key="-1" :value="null" disabled selected>Pick-up location</option>
							<option v-for="(branch, index) in $store.state.location.list" :key="index" :value="branch">{{ branch.name }}</option>
						</select>
					</client-only>
				</label>
			</div>

			<div class="col-span-7 tabletWide:col-span-2 tabletWide:col-start-1 tabletWide:row-start-2">
				<button id="datePickerStart" :class="[!datePicker.start ? 'text-goGrayDark' : 'text-goBlack ', errors.has('Pick-up date', 'formItinerary') ? 'text-goError border-goError bg-goErrorBright' : 'border-transparent focus-within:border-goGrayBright  bg-goGrayBrighter']" class="w-full text-left border-2 rounded-3 py-6 px-6 cursor-pointer outline-0 whitespace-nowrap overflow-hidden" data-test="pickUpDate" @keyup.tab="toggleDatePicker" @keydown.tab="toggleDatePicker" @click="toggleDatePicker">{{ !datePicker.start ? "Date" : $date.format("DDD D MMM YYYY", datePicker.start) }}</button>
				<client-only>
					<span v-if="isDatePickerLoaded" v-show="showDatePicker === 'datePickerStart'" class="absolute block z-30 ml-8 -mt-8">
						<datePicker v-model="datePicker.start" v-validate="'required'" :is-required="true" :is-inline="true" :min-date="$date.toNZ($date.today)" :formats="{ title: 'MMMM YYYY', input: ['WWW DD MMM YYYY'], weekdays: 'WW' }" name="Pick-up date" class="goDatePicker" tint-color="#E21266" @dayclick="datePickerCalendarDayclick" />
					</span>
				</client-only>
			</div>

			<div class="col-span-5 tabletWide:col-span-2 tabletWide:col-start-3 tabletWide:row-start-2">
				<label :class="[errors.has('Pick-up time', 'formItinerary') ? 'border-goError bg-goErrorBright text-goError' : 'text-goBlack border-transparent focus-within:border-goGrayBright  bg-goGrayBrighter']" for="timePickerStart" class="w-full flex min-h-40 flex-col border-2 rounded-3 py-6 px-6" data-test="pickUpTime">
					<client-only>
						<multiselect v-if="$store.state.browser.breakpoint.isTablet" v-model="timePicker.start" v-validate="'required'" :allow-empty="false" :searchable="false" :options="timePickerStartOptions" :show-labels="false" :option-height="24" class="h-24" placeholder="Pick-up time" name="Pick-up time" label="text12" track-by="text12" open-direction="bottom" />

						<select v-else id="timePickerStart" v-model="timePicker.start" v-validate="'required'" name="Pick-up time" class="w-full border-0 bg-transparent p-0 outline-0 h-24 cursor-pointer">
							<option :key="-1" :value="null" disabled selected class="text-goBlack">Time</option>
							<template v-if="$store.state.userFirstActionHasHappened">
								<option v-for="(time, index) in timePickerStartOptions" :key="index" :value="time" class="text-goBlack">{{ time.text12 }}</option>
							</template>
							<template v-else>
								<option :value="timePicker.start" class="text-goBlack">{{ timePicker.start.text12 }}</option>
							</template>
						</select>
					</client-only>
				</label>
			</div>

			<div class="col-span-12 tabletWide:col-span-4 tabletWide:col-start-5 tabletWide:row-start-1" :class="[$store.state.hasPickupLocation ? '' : 'hidden tablet:unset']">
				<label :class="[errors.has('Drop-off location', 'formItinerary') ? 'border-goError bg-goErrorBright text-goError' : 'text-goBlack  border-transparent focus-within:border-goGrayBright  bg-goGrayBrighter']" for="locationPickerEnd" class="w-full flex min-h-40 flex-col border-2 rounded-3 py-6 px-6" data-test="dropOffLocation">
					<span v-show="branchPicker.end" class="text-14 leading-20 text-goGrayDarker">Drop-off</span>
					<client-only>
						<multiselect v-if="$store.state.browser.breakpoint.isTablet" v-model="branchPicker.end" v-validate="'required'" :allow-empty="false" :searchable="false" :disabled="!$store.state.location.list.length" :options="$store.state.location.list" :show-labels="false" :option-height="24" class="h-24" placeholder="Drop-off location" name="Drop-off location" label="name" track-by="name" open-direction="bottom" />

						<select v-else id="locationPickerEnd" v-model="branchPicker.end" v-validate="'required'" :disabled="!$store.state.location.list.length" name="Drop-off location" class="w-full border-0 bg-transparent p-0 outline-0 h-24 cursor-pointer">
							<option :key="-1" :value="null" disabled selected class="text-goBlack">Drop-off location</option>
							<option v-for="(branch, index) in $store.state.location.list" :key="index" :value="branch" class="text-goBlack">{{ branch.name }}</option>
						</select>
					</client-only>
				</label>
			</div>

			<div class="col-span-7 tabletWide:col-span-2 tabletWide:col-start-5 tabletWide:row-start-2" :class="[$store.state.hasPickupLocation ? '' : 'hidden tablet:unset']">
				<button id="datePickerEnd" :class="[!datePicker.end ? 'text-goGrayDark' : 'text-goBlack ', errors.has('Drop-off date', 'formItinerary') ? 'text-goError border-goError bg-goErrorBright' : 'border-transparent focus-within:border-goGrayBright  bg-goGrayBrighter']" class="w-full text-left border-2 rounded-3 py-6 px-6 cursor-pointer outline-0 whitespace-nowrap overflow-hidden" data-test="dropOffDate" @keyup.tab="toggleDatePicker" @keydown.tab="toggleDatePicker" @click="toggleDatePicker">{{ !datePicker.end ? "Date" : $date.format("DDD D MMM YYYY", datePicker.end) }}</button>
				<client-only>
					<span v-if="isDatePickerLoaded" v-show="showDatePicker === 'datePickerEnd'" class="absolute block z-30 ml-8 -mt-8">
						<datePicker v-model="datePicker.end" v-validate="'required'" :is-required="true" :is-inline="true" :min-date="datePicker.start || $date.toNZ($date.today)" :formats="{ title: 'MMMM YYYY', input: ['WWW DD MMM YYYY'], weekdays: 'WW' }" name="Drop-off date" class="goDatePicker" tint-color="#E21266" @dayclick="datePickerCalendarDayclick" />
					</span>
				</client-only>
			</div>

			<div class="col-span-5 tabletWide:col-span-2 tabletWide:col-start-7 tabletWide:row-start-2" :class="[$store.state.hasPickupLocation ? '' : 'hidden tablet:unset']">
				<label :class="[errors.has('Drop off time', 'formItinerary') ? 'border-goError bg-goErrorBright text-goError' : 'text-goBlack border-transparent focus-within:border-goGrayBright  bg-goGrayBrighter']" for="timePickerEnd" class="w-full flex min-h-40 flex-col border-2 rounded-3 py-6 px-6" data-test="dropOffTime">
					<client-only>
						<multiselect v-if="$store.state.browser.breakpoint.isTablet" v-model="timePicker.end" v-validate="'required'" :allow-empty="false" :searchable="false" :options="timePickerEndOptions" :show-labels="false" :option-height="24" class="h-24" placeholder="Drop off time" name="Drop off time" label="text12" track-by="text12" open-direction="bottom" />

						<select v-else id="timePickerEnd" v-model="timePicker.end" v-validate="'required'" name="Drop off time" class="w-full border-0 bg-transparent p-0 outline-0 h-24 cursor-pointer">
							<option :key="-1" :value="null" disabled selected class="text-goBlack">Time</option>
							<template v-if="$store.state.userFirstActionHasHappened">
								<option v-for="(time, index) in timePickerEndOptions" :key="index" :value="time" class="text-goBlack">{{ time.text12 }}</option>
							</template>
							<template v-else>
								<option :value="timePicker.end" class="text-goBlack">{{ timePicker.end.text12 }}</option>
							</template>
						</select>
					</client-only>
				</label>
			</div>

			<client-only>
				<div class="col-span-12 tabletWide:col-span-8 tabletWide:col-start-1 tabletWide:row-start-3" :class="[$store.state.hasPickupLocation ? '' : 'hidden tablet:unset']">
					<button type="button" class=" border-transparent focus-within:border-goGrayBright tabletWide:focus-within:border-transparent bg-goGrayBrighter tabletWide:bg-transparent w-full text-left border-2 rounded-3 p-6 tabletWide:p-0 cursor-pointer outline-0 tabletWide:hidden" :class="[$store.state.itinerary.promoCode ? 'hidden' : '' ]" data-test="addAPromoCode" @click="toggleFormPromo">
						<span class="text-14 whitespace-nowrap text-goGrayDarker" :class="[
						theme === 'default' ? 'tabletWide:text-goBlue' : '',
						theme === 'ghost' ? 'tabletWide:text-white tabletWide:text-shadow-caption' : '',
						]">Add a promo code</span>
					</button>

					<div class=" border-transparent focus-within:border-goGrayBright bg-goGrayBrighter w-full border-2 rounded-3 p-6 flex justify-between items-center " :class="[$store.state.itinerary.promoCode ? '' : 'hidden' ]">
						<span>
							<span class="hidden tabletWide:unset text-14 text-goGrayDarker">Promo code</span>
							<span class="uppercase text-16">{{ $store.state.itinerary.promoCode }}</span>
						</span>
						<button type="button" class="text-goBlue text-12 px-8" @click="toggleFormPromo">Remove</button>
					</div>
				</div>
			</client-only>

			<div class="col-span-12 tabletWide:col-span-4 tabletWide:col-start-9 tabletWide:row-start-1 tabletWide:row-span-2 tabletWide:justify-self-center tabletWide:justify-self-center tabletWide:self-center tabletWide:flex tabletWide:flex-col tabletWide:items-center tabletWide:justify-around tabletWide:h-full">
				<button ref="findMyCarButton" class="button button-goPink button-normal tabletWide:button-large space-x-12 group" data-test="findMyCar" @click="fetchVehicles">
					<span>Find my car</span>
					<svg-icon name="arrow-forward" class="iconSize-24 opacity-50 translate-x-0 group-hover:translate-x-1" />
				</button>

				<button type="button" class=" border-transparent focus-within:border-goGrayBright tabletWide:focus-within:border-transparent bg-goGrayBrighter tabletWide:bg-transparent w-full border-2 rounded-3 p-6 tabletWide:p-0 cursor-pointer outline-0 hidden" :class="[$store.state.itinerary.promoCode ? '' : 'tabletWide:unset' ]" data-test="addAPromoCode" @click="toggleFormPromo">
					<span class="text-14 whitespace-nowrap text-goGrayDarker" :class="[
					theme === 'default' ? 'tabletWide:text-goBlue' : '',
					theme === 'ghost' ? 'tabletWide:text-white tabletWide:text-shadow-caption' : '',
					]">Add a promo code</span>
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import datePicker from "~/plugins/lazyDatePicker";

export default {
	components: {
		multiselect: () => import(/* webpackChunkName: "vue-multiselect" */"vue-multiselect"),
		errorMessage: () => import(/* webpackChunkName: "errorMessage" */"~/components/errorMessage.vue"),
		datePicker,
	},
	inject: ["$validator"],
	props: {
		redirectPath: {
			type: [String, Object],
			default: "/vehicles/",
		},
		agentId: {
			type: String,
			default: "",
		},
		branchPickerStartCode: {
			type: String,
			default: null,
		},
		branchPickerEndCode: {
			type: String,
			default: null,
		},
		promoCode: {
			type: String,
			default: "",
		},
		clientReferenceNumber: {
			type: String,
			default: "",
		},
		searchType: {
			type: String,
			default: "vehicles",
		},
		showLabels: {
			type: Boolean,
			default: false,
		},
		theme: {
			// ghost theme is intended to be used when the itinerary is over an image.
			type: String,
			default: "default",
			validator: (theme) => ["ghost", "default"].includes(theme),
		},
	},
	data() {
		return {
			showDatePicker: null,
			isDatePickerLoaded: false,
			branchPicker: {
				start: this.$store.state.itinerary.pickup.branch,
				end: this.$store.state.itinerary.dropOff.branch,
			},

			datePicker: {
				start: this.$store.state.itinerary.pickup.date || this.$date.add(this.$date.today, 2, "days"),
				end: this.$store.state.itinerary.dropOff.date || this.$date.add(this.$date.today, 5, "days"),
			},

			timePicker: {
				start: this.$store.state.itinerary.pickup.time || this.$time.range.find((time) => time.text24 === "10:00"),
				end: this.$store.state.itinerary.dropOff.time || this.$time.range.find((time) => time.text24 === "10:00"),
			},
		};
	},
	computed: {
		timePickerStartOptions() {
			return this.getTimePickerOptions(this.branchPicker.start, this.datePicker.start);
		},
		timePickerEndOptions() {
			return this.getTimePickerOptions(this.branchPicker.end, this.datePicker.end);
		},
		hasPickupLocation() {
			return !!this.branchPicker.start;
		},
		searchTypeAction() {
			let action;
			if (this.searchType === "deals") {
				action = "fetchDeals";
			} else if (this.searchType === "vehicles") {
				action = "fetch";
			}
			return action;
		},
		currentItinerary() {
			return {
				pickup: {
					branch: this.branchPicker.start,
					date: this.datePicker.start,
					time: this.timePicker.start,
				},

				dropOff: {
					branch: this.branchPicker.end,
					date: this.datePicker.end,
					time: this.timePicker.end,
				},

				agentId: this.agentId || this.$store.state.itinerary.agentId,
				promoCode: this.promoCode || this.$store.state.itinerary.promoCode,
				clientReferenceNumber: this.clientReferenceNumber || this.$store.state.itinerary.clientReferenceNumber,
			};
		}
	},
	watch: {
		hasPickupLocation(val) {
			this.$store.commit("updateHasPickupLocation", val);
			setTimeout(() => { this.$refs.itinerary.scrollIntoView({ behavior: "smooth", block: "end" }) }, "100")
		},
		"branchPicker.start"(newBranch, oldBranch) {
			if (!this.branchPicker.end) {
				this.branchPicker.end = newBranch;
			}

			// prettier-ignore
			this.timePicker.start = this.timePicker.start
				? this.resetTimePickerOptions(this.timePicker.start, this.timePickerStartOptions)
				: newBranch
				? this.timePickerStartOptions.find(time => time.text24 === "10:00")
				: null;
		},
		"branchPicker.end"(newBranch, oldBranch) {
			if (!this.branchPicker.start) {
				this.branchPicker.start = newBranch;
			}

			// prettier-ignore
			this.timePicker.end = this.timePicker.end
				? this.resetTimePickerOptions(this.timePicker.end, this.timePickerEndOptions)
				: newBranch
				? this.timePickerEndOptions.find(time => time.text24 === "10:00")
				: null;
		},
		"datePicker.start"(newDate, oldDate) {
			if (!oldDate || newDate > this.datePicker.end) {
				this.datePicker.end = this.$date.add(newDate, 3, "days");
			}

			this.timePicker.start = this.resetTimePickerOptions(this.timePicker.start, this.timePickerStartOptions);
		},
		"datePicker.end"(newDate, oldDate) {
			if (!oldDate) {
				this.datePicker.start = this.datePicker.end;
			}

			this.timePicker.end = this.resetTimePickerOptions(this.timePicker.end, this.timePickerEndOptions);
		},
		"$store.state.hasItineraryErrors"(bool) {
				if (bool) this.$scrollTo()
		},
	},
	mounted() {
		this.$store.commit("updateHasPickupLocation", this.hasPickupLocation);
		if (this.branchPickerStartCode) {
			this.branchPicker.start = this.$store.state.itinerary.pickup.branch || this.$store.getters["location/findBy"]('code', this.branchPickerStartCode);
		}
		if (this.branchPickerEndCode) {
			this.branchPicker.end = this.$store.state.itinerary.dropOff.branch || this.$store.getters["location/findBy"]('code', this.branchPickerEndCode);
		}
	},
	beforeDestroy() {
		this.$store.commit("updateItineraryErrors", null)
	},
	methods: {
		showErrors(error) {
			const message = error?.message ?? error
			const errorMessages = []
			if (message) errorMessages.push(message)
			if (errorMessages.length) {
				this.$store.commit("updateItineraryErrors", {
					title: 'Oops!',
					content: errorMessages
				})
			}
		},
		fetchVehicles() {
			const isValid = (valid) => {
				this.$store.commit("updateItineraryErrors", null)
				return valid
					? Promise.resolve(valid)
					: Promise.reject() // eslint-disable-line
			};

			const resetCurrentQuery = () => {
				this.$store.commit("vehicle/updateCurrentQuery", {});
			};

			const formToItinerary = () => {
				return this.currentItinerary;
			};

			const redirect = () => {
				this.$router.push(this.redirectPath);
			};
			const scrollToResults = () => {
				if (this.$store.state.browser.breakpoint.isMobileOnly) {
					this.$scrollTo();
				}
			};
			this.$validator
				.validateAll("formItinerary")
				.then(isValid)
				.then(resetCurrentQuery)
				.then(formToItinerary)
				.then((itinerary) => this.$store.dispatch("vehicle/" + this.searchTypeAction, itinerary))
				.then(redirect)
				.then(scrollToResults)
				.catch(this.showErrors);
		},
		resetTimePickerOptions(selectedTime, options) {
			return !!selectedTime && !this.$time.isInRange(selectedTime, options) ? null : selectedTime;
		},
		getTimePickerOptions(selectedLocation, selectedDate) {
			let options = this.$time.range;

			// if selected date is today prevent older time
			if (!!selectedDate && this.$date.isToday(selectedDate)) {
				const from = this.$date.today.getHours() * 100 + this.$date.today.getMinutes();
				const to = 2359; //  23:59

				options = this.$time.range.filter((timeObj) => {
					return timeObj.num24 >= from && timeObj.num24 <= to;
				});
			}

			return options;
		},
		datePickerCalendarDayclick(event) {
			if (!event.attributesMap.disabled) {
				this.toggleDatePicker(null);
			}
		},
		toggleDatePicker(event = null) {
			this.isDatePickerLoaded = true;

			const isADatePickerInput = !!event && event.target.id.includes("datePicker");
			const isACalendar = !!event && event.target.closest(".goDatePicker");
			const isKeyDown = !!event && event.type === "keydown";

			if (isADatePickerInput && !isKeyDown) {
				this.showDatePicker = event.target.id;
				document.body.addEventListener("click", this.toggleDatePicker, true);
			} else if (!isACalendar) {
				this.showDatePicker = null;
				document.body.removeEventListener("click", this.toggleDatePicker, true);
			}
		},
		toggleFormPromo() {
			if (!this.$store.state.itinerary.promoCode) {
				this.$store.dispatch("itinerary/togglePromo", { currentItinerary: this.currentItinerary })
			} else {
				this.$store.dispatch("itinerary/removePromoCode", { searchTypeAction: this.searchTypeAction })
			}
		},
	},
}
</script>
