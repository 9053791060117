const settings = {
	locale: "en-NZ",
	themeStyles: {
		// ? https://vcalendar-legacy.netlify.com/api.html#theme-styles
		dayCellNotInMonth: {
			display: "none",
			pointerEvents: "none",
		},
	},
	datePickerDisabledAttribute: {
		contentStyle: (cell) => {
			return {
				color: "inherit",
				opacity: 6 / 12,
				...(cell.isHovered && {
					cursor: "not-allowed",
					backgroundColor: "transparent",
				}),
			};
		},
	},
};

// Don't lazy load the component in the .vue file
// Use: import datePicker from "~/plugins/lazyDatePicker"
// Next line will lazy load the component
export default () =>
	import(/* webpackChunkName: "v-calendar" */"v-calendar").then(({ setupCalendar, DatePicker }) => {
		setupCalendar(settings);
		return DatePicker;
	});
